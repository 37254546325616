/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';

import Header from './header';
import '../../static/styles/main.scss';
import logo from '../../static/images/logo.svg';

const Layout = ({ children, isPageExpired }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header
          siteTitle={data.site.siteMetadata.title}
          isPageExpired={isPageExpired}
        />
        <main>{children}</main>
        <footer>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'widget__item'}>
                <div className={'logo'}>
                  <Link to='/' title='PeakRx Therapy Logo'>
                    <img
                      alt={'Logo'}
                      src={logo}
                      style={{
                        width: '200px',
                        height: 'auto',
                        marginBottom: '40px',
                      }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className={'copyright'}>
            <p>
              Copyright {new Date().getFullYear()}, {` `}{' '}
              <a
                href='https://peakrxtherapy.com/'
                target='_blank'
                title='PeakRx Therapy'
              >
                PeakRx Therapy
              </a>
              . All rights reserved.
            </p>
          </div>
        </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
